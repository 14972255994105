import React, { useCallback, useEffect, useState } from 'react';
import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { Footer } from '../components/core/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  navbarColorAtom,
  stepThroughDataAtom,
  stepThroughMetaDataAtom
} from 'src/atoms';
import { useAtom } from 'jotai';
import { ContractSelection } from '../components/pre-step-through/ContractSelection';
import { IndustrySelection } from '../components/pre-step-through/IndustrySelection';
import { RoleSelection } from '../components/pre-step-through/RoleSelection';
import { ChartaBack } from 'src/components/core/ChartaBack';
import { getContractType } from '../api';
import { Answer } from 'src/interfaces';
import { preStepThroughTheme } from 'src/theme/theme';

export interface PreStepThroughProps extends FlexProps {}

export const PreStepThrough: React.FC<PreStepThroughProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const bg = useColorModeValue('#EEEEEE', '#1E2029');
  const mainBg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );
  const color = useColorModeValue('#1B1C31', 'brand.secondary1');
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const [view, setView] = useState<string>('contract');
  const [contractType, setContractType] = useState<any>('');
  const [, setStepThroughData] = useAtom(stepThroughDataAtom);
  const [, setStepThroughMetaData] = useAtom(stepThroughMetaDataAtom);
  const [searchParams] = useSearchParams();

  const goToStepThrough = useCallback(
    (role: string, id?: string) => {
      let updatedAnswers: Answer[] = [
        {
          stepId: 'role',
          subStepId: 'role',
          questionId: 'role',
          fieldId: 'role',
          value: role
        }
      ];
      setStepThroughData((data) => ({
        ...data,
        formAnswers: updatedAnswers
      }));

      navigate(`/step-through/${id || contractType._id}`, { replace: true });
    },
    [contractType._id, navigate, setStepThroughData]
  );

  useEffect(() => {
    const contract = searchParams.get('contract');
    const industry = searchParams.get('industry');
    const role = searchParams.get('role');

    const redirect = async () => {
      if (contract !== null && industry !== null && role !== null) {
        const result = await getContractType(contract);
        setContractType(result.data);

        let hasMeta = false;
        let meta: any = {};
        searchParams.forEach((value, key) => {
          if (key !== 'contract' && key !== 'industry' && key !== 'role') {
            hasMeta = true;
            meta[key] = value;
          }
        });

        if (hasMeta) {
          setStepThroughMetaData(meta);
        } else {
          setStepThroughMetaData(null);
        }

        goToStepThrough(role, result.data._id);
      }
    };

    redirect();
  }, [goToStepThrough, setContractType, searchParams, setStepThroughMetaData]);

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContractSelected = async (id: string) => {
    const result = await getContractType(id);
    setContractType(result.data);
    setView('role');
  };

  const handleIndustrySelected = (id: string) => {
    setView('contract');
  };

  const handleRoleSelected = (role: string) => {
    goToStepThrough(role);
  };

  const onBack = () => {
    setView('contract');
    // if (view === 'role') {
    //   setView('contract');
    // } else if (view === 'contract') {
    //   setView('industry');
    // }
  };

  return (
    <Flex
      background={preStepThroughTheme.background}
      overflowY="hidden"
      flexDirection="column"
      justifyContent="space-between"
      h="100vh"
      overflow="hidden"
      {...props}
    >
      <Flex
        flexGrow="1"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          bg={preStepThroughTheme.background}
          borderRadius={{ base: '0', lg: '5px' }}
          h={{ base: '100%', lg: '80%' }}
          w={{ base: '100vw', lg: '655px' }}
          flexDirection="column"
          alignItems="center"
          color={color}
          position="relative"
          overflow="auto"
          p={{ base: '20px', lg: '60px' }}
          filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
        >
          {view !== 'contract' && (
            <Flex alignSelf="flex-start">
              <ChartaBack
                label="Back"
                color={preStepThroughTheme.highlightColor}
                onClick={() => onBack()}
              />
            </Flex>
          )}
          {view === 'industry' ? (
            <IndustrySelection onIndustrySelected={handleIndustrySelected} />
          ) : view === 'contract' ? (
            <ContractSelection onContractSelected={handleContractSelected} />
          ) : (
            <RoleSelection
              onRoleSelected={handleRoleSelected}
              roles={contractType.roles}
              isOneSided={contractType.oneSided}
              ignoreCredits={contractType.ignoreCredits}
            />
          )}
        </Flex>
      </Flex>
      <Footer display={{ base: 'none', lg: 'initial' }} variant="transparent" />
      <Footer display={{ base: 'initial', lg: 'none' }} />
    </Flex>
  );
};
