import React, { useEffect, useState } from 'react';
import { Flex, Box, Heading } from '@chakra-ui/react';
import { getContractTypes } from 'src/api';
import { preStepThroughTheme } from 'src/theme/theme';

export interface ContractSelectionProps {
  onContractSelected: (id: string) => void;
}

export const ContractSelection: React.FC<ContractSelectionProps> = ({
  onContractSelected
}) => {
  const [contracts, setContracts] = useState<
    { _id: string; name: string; status: string; hidden: boolean }[]
  >([]);

  useEffect(() => {
    const getContracts = async () => {
      const { data } = await getContractTypes();
      setContracts(data);
    };

    getContracts();
  }, []);

  return (
    <>
      <Heading
        textAlign="center"
        mb="32px"
        color={preStepThroughTheme.headingTextColor}
      >
        What type of contract are you looking for?
      </Heading>

      {contracts.map((contract) => {
        if (contract.hidden) {
          return <React.Fragment key={contract._id}></React.Fragment>;
        }

        return (
          <Flex
            key={contract._id}
            mb="24px"
            py="20px"
            cursor="pointer"
            onClick={() => {
              if (contract.status === 'Active') {
                onContractSelected(contract._id);
              }
            }}
            data-heap-id={`contract-type.${contract.name}`}
            w={{ base: '100%', lg: '350px' }}

            borderRadius="6px"
            border="1px solid"
            borderColor="brand.primary"
            justifyContent="center"
            color="brand.primary"
            position="relative"
            _hover={{
              bg:
                contract.status === 'Active'
                  ? preStepThroughTheme.highlightColor
                  : 'none',
              color:
                contract.status === 'Active'
                  ? preStepThroughTheme.highlightTextColor
                  : 'brand.primary',
              borderColor:
                contract.status === 'Active'
                  ? preStepThroughTheme.highlightColor
                  : 'white'
            }}
          >
            {contract.name}
            <Box
              hidden={contract.status === 'Active'}
              as="span"
              position="absolute"
              marginX="auto"
              bottom="-15px"
              p="2px"
              bg={preStepThroughTheme.comingSoonColor}
              color={preStepThroughTheme.comingSoonTextColor}
              borderRadius="6px"
            >
              Coming Soon
            </Box>
          </Flex>
        );
      })}
    </>
  );
};
