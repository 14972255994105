import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import {
  getPaymentPlans,
  getStripeUpgradeUrl,
  getStripeUrl
} from 'src/api/payment';
import { navbarColorAtom, userAtom } from 'src/atoms';
import { Close } from 'src/components/icons/Close';
import { Tick } from 'src/components/icons/Tick';
import { MembershipType } from 'src/enums/membership';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { openInCurrentTab } from 'src/utils/openInTabHelper';
import { AlertModal } from 'src/components/core/AlertModal';
import { ActionCard } from 'src/components/dashboard/ActionCard';
import { Helmet } from 'react-helmet';
import { getClientReferenceId } from '../../utils/getClientReferenceId';

export interface MembershipProps extends FlexProps {}

export interface MembershipPaymentPlan {
  id: string;
  length: number;
  name: string;
  description: string;
  creditsAmount: number;
  type?: string;
}

export const Membership: React.FC<MembershipProps> = ({ ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { showLoading, showServerError, hideLoading } = useApiHelpers();

  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const [user] = useAtom(userAtom);

  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const [stripeUrl, setStripeUrl] = useState<string>('');
  const [paymentPlans, setPaymentPlans] = useState<MembershipPaymentPlan[]>([]);
  const [currentPlan, setCurrentPlan] = useState<
    MembershipPaymentPlan | undefined
  >();
  const [planUpgradeData, setPlanUpgradeData] = useState<any>();
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation
  } = useDisclosure();
  const {
    isOpen: isBasicConfirmationOpen,
    onClose: onCloseBasicConfirmation,
    onOpen: onOpenBasicConfirmation
  } = useDisclosure();

  const {
    isOpen: isSuccessOpen,
    onClose: onCloseSuccess,
    onOpen: onOpenSuccess
  } = useDisclosure();

  const [shouldPlanBeDisabledMap, setSHouldPlanBDisableddMap] = useState<any>(
    {}
  );

  // Sort payment types by annual/monthly and basic/plus/pro
  const sortPaymentType = (pay: MembershipPaymentPlan) => {
    switch (pay.name) {
      case MembershipType.BEGINNER:
        return MembershipType.BEGINNER;
      case MembershipType.BASIC:
        return pay.length === 1
          ? MembershipType.BASIC_MONTH
          : MembershipType.BASIC_ANNUAL;
      case MembershipType.PLUS:
        return pay.length === 1
          ? MembershipType.PLUS_MONTH
          : MembershipType.PLUS_ANNUAL;
      case MembershipType.PRO:
        return pay.length === 1
          ? MembershipType.PRO_MONTH
          : MembershipType.PRO_ANNUAL;
      default:
        return '';
    }
  };

  // Get Stripe URL from API
  const callStripePaymentLink = useCallback(
    async (req: any, upgrade: boolean = false) => {
      try {
        let data;
        if (!upgrade) {
          const response = await getStripeUrl(req);
          data = response.data;
          setStripeUrl(data?.url);
        } else {
          onCloseConfirmation();
          const response = await getStripeUpgradeUrl(req);
          data = response.data;
          onOpenSuccess();
        }
      } catch (error) {
        showServerError();
      }
    },
    [onCloseConfirmation, onOpenSuccess, showServerError]
  );

  const handleSelectClick = useCallback(
    async (planId: string) => {
      const req = {
        priceId: (paymentPlans.find((p) => p.type === planId) || {}).id || '',
        successUrl: `${process.env.REACT_APP_STRIPE_URL}/payment-successful`,
        cancelUrl: `${process.env.REACT_APP_STRIPE_URL}/dashboard/membership?monthly=${isMonthly}`,
        rewardfulClientId: getClientReferenceId()
      };
      if (planId === MembershipType.BEGINNER) {
        setPlanUpgradeData(req);
        onOpenBasicConfirmation();
      } else if (!user?.currentStripePricePlanId) {
        await callStripePaymentLink(req, false);
      } else {
        setPlanUpgradeData(req);
        onOpenConfirmation();
      }
    },
    [
      callStripePaymentLink,
      isMonthly,
      onOpenBasicConfirmation,
      onOpenConfirmation,
      paymentPlans,
      user?.currentStripePricePlanId
    ]
  );

  // display the labeling for action card
  const displayCardLabel = useCallback(
    ({
      header,
      slashedPrice,
      pricing
    }: {
      header: string;
      slashedPrice?: number;
      pricing: string;
    }) => (
      <Flex flexFlow="column">
        <Text
          fontFamily="Open Sans"
          fontSize="16px"
          fontWeight="400"
          lineHeight="20px"
          mb="5px"
        >
          {header}
        </Text>
        <Flex alignItems="center">
          <Box mr={slashedPrice ? '20px' : '0'} position="relative">
            {slashedPrice && (
              <Text
                p="0 2px"
                fontFamily="Open Sans"
                fontSize="16px"
                fontWeight="700"
                _before={{
                  position: 'absolute',
                  content: `""`,
                  left: 0,
                  top: '50%',
                  right: 0,
                  borderTop: '3px solid',
                  borderColor: '#F4B557',
                  '-webkit-transform': 'skewY(-25deg)',
                  '-moz-transform': 'skewY(-25deg)',
                  transform: 'skewY(-25deg)'
                }}
              >
                ${slashedPrice}
              </Text>
            )}
          </Box>
          <Text
            fontFamily="Open Sans"
            textAlign="center"
            fontSize="20px"
            lineHeight="20px"
            color="#F4B557"
          >
            <b>{pricing}</b>
          </Text>
        </Flex>
      </Flex>
    ),
    []
  );

  const displayMobileBasicPaymentPlan = useMemo(() => {
    return [
      {
        label: displayCardLabel({
          // header: `Basic - ${isMonthly ? 1 : 12} Send Credit${
          //   !isMonthly ? 's' : ''
          // }`,
          header: 'Beginner',
          pricing: '$30/contract'
        }),
        button: {
          buttonAction: () => handleSelectClick(MembershipType.BEGINNER)
        }
      }
    ].map((plan, idx) => (
      // display action each tiers action card
      <Flex pb="8px">
        <ActionCard
          key={idx}
          {...plan}
          cardStyle={{ backgroundColor: 'brand.secondary2' }}
        />
      </Flex>
    ));
  }, [displayCardLabel, handleSelectClick]);

  // display the list of action cards showing payment plans
  // in mobile
  const displayMobilePaymentPlans = useMemo(() => {
    return [
      {
        label: displayCardLabel({
          header: `Intermediate - ${isMonthly ? 2 : 24} Contracts`,
          slashedPrice: isMonthly ? 60 : 720,
          pricing: isMonthly ? '$50/month' : '$500/year'
        }),
        button: {
          buttonAction: () =>
            handleSelectClick(
              isMonthly ? MembershipType.PLUS_MONTH : MembershipType.PLUS_ANNUAL
            ),
          disabled:
            shouldPlanBeDisabledMap[
              isMonthly
                ? MembershipType.PLUS_MONTH.toString()
                : MembershipType.PLUS_ANNUAL.toString()
            ]
        }
      },
      {
        label: displayCardLabel({
          header: `Pro - ${isMonthly ? 3 : 36} Contracts`,
          slashedPrice: isMonthly ? 90 : 1080,
          pricing: isMonthly ? '$60/month' : '$650/year'
        }),
        button: {
          buttonAction: () =>
            handleSelectClick(
              isMonthly ? MembershipType.PRO_MONTH : MembershipType.PRO_ANNUAL
            ),
          disabled:
            shouldPlanBeDisabledMap[
              isMonthly
                ? MembershipType.PRO_MONTH.toString()
                : MembershipType.PRO_ANNUAL.toString()
            ]
        }
      },
      {
        label: (
          <Flex alignItems="center" gap="10px">
            <Text
              fontFamily="Open Sans"
              fontSize="16px"
              fontWeight="700"
              lineHeight="20px"
            >
              Need more contracts?
            </Text>
            {/* <Text
              fontFamily="Open Sans"
              fontSize="16px"
              fontWeight="400"
              lineHeight="20px"
            >
              Receive Offers Only*
            </Text> */}
          </Flex>
        ),
        button: {
          buttonAction: () =>
            window.open('https://getcharta.com/contact', '_blank'),
          buttonText: 'Contact Us'
        },
        cardStyle: {
          backgroundColor: 'rgba(27, 28, 49, 0.5) !important'
        }
      }
    ].map((plan, idx) => (
      // display action each tiers action card
      <Flex pb="8px">
        <ActionCard
          key={idx}
          {...plan}
          cardStyle={{ backgroundColor: 'brand.secondary2', ...plan?.cardStyle }}
        />
      </Flex>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayCardLabel,
    isMonthly,
    paymentPlans,
    user?.currentStripePricePlanId
  ]);

  const setPaymentPlansList = useCallback(async () => {
    const { data } = await getPaymentPlans();
    setPaymentPlans(
      data.map((d: MembershipPaymentPlan) => ({
        ...d,
        type: sortPaymentType(d)
      }))
    );
  }, []);

  // if payment plan exists for user, disable that plans button
  useEffect(() => {
    if (!!user && !!user.currentStripePricePlanId && paymentPlans.length > 0) {
      const plan =
        paymentPlans.find(
          (plan) => plan.id === user.currentStripePricePlanId
        ) || undefined;
      setCurrentPlan(plan);
      setIsMonthly(plan?.length === 1);

      const indexOfCurrentPlan = _.findIndex(
        paymentPlans,
        (plan) => plan.id == user.currentStripePricePlanId
      );
      const isCurrentPlanAnnual = plan!.length == 12;
      const isCurrentPlanMonthly = !isCurrentPlanAnnual;
      const disableMonthlyPlans = isCurrentPlanAnnual;
      const newMap = paymentPlans.reduce((map: any, planBeingChecked, idx) => {
        if (
          idx <= indexOfCurrentPlan ||
          (disableMonthlyPlans && planBeingChecked.length < 12)
        ) {
          map[planBeingChecked.type!] = true;
        } else {
          map[planBeingChecked.type!] = false;
        }
        if (isCurrentPlanMonthly && planBeingChecked.length == 12) {
          debugger;
          map[planBeingChecked.type!] = false;
        }
        return map;
      }, {});
      setSHouldPlanBDisableddMap(newMap);
      debugger;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, paymentPlans]);

  // Call payment plan list
  useEffect(() => {
    try {
      showLoading();
      setPaymentPlansList();
    } catch {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPaymentPlansList]);

  // Open up Stripe Url
  useEffect(() => {
    if (!!stripeUrl) openInCurrentTab(stripeUrl);
  }, [stripeUrl]);

  // After CancelRedirect from Stripe page,
  // check if user was on monthly or annually and properly update
  useEffect(() => {
    if (!!searchParams.get('monthly')) {
      const monthly = searchParams.get('monthly') === 'true';
      setIsMonthly(monthly);
      searchParams.delete('monthly');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        {`<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-54ZCKP9');</script>`}
      </Helmet>
      <Flex
        flexDir="column"
        {...props}
        bg="linear-gradient(#664665, #4A6265)"
        h="100vh"
        padding="8px 8px 32px 8px"
        overflow="auto"
      >
        <AlertModal
          isOpen={isConfirmationOpen}
          onAccept={() => callStripePaymentLink(planUpgradeData, true)}
          onDecline={onCloseConfirmation}
          acceptText="Continue"
          declineText="Cancel"
          title="Looks like you are changing your plan!"
          text="Credits for monthly subscriptions will be funded when your subscription renews."
          text2="Credits for annual subscriptions will be immediately funded after your payment is processed."
          variant="warning"
        />
        <AlertModal
          isOpen={isBasicConfirmationOpen}
          onAccept={() => callStripePaymentLink(planUpgradeData, false)}
          onDecline={onCloseBasicConfirmation}
          acceptText="Continue"
          declineText="Cancel"
          title="You've selected to purchase a single contract without a subscription."
          text="This credit can be used on any of the contracts on our platform and will not expire."
          text2="Please note that the subscription plans include discounted rates for credits and are a better deal long term."
          variant="warning"
        />
        <AlertModal
          isOpen={isSuccessOpen}
          onAccept={() => {
            onCloseSuccess();
            window.location.reload();
          }}
          acceptText="Continue"
          title="Your payment plan was successfully upgraded!"
          variant="success"
        />
        <Flex flexDir="column" alignItems="center" mb="64px">
          <Text
            fontFamily="Poppins"
            fontSize="32px"
            textAlign="center"
            width="100%"
            mt="8px"
          >
            Pricing
          </Text>
          <Text
            mt="12px"
            textAlign="center"
            maxWidth={{ base: '100%', lg: '80%' }}
            p="0 32px"
          >
            Purchase single contracts for as low as $30 or sign up for a
            subscription to save and earn even more money on your agreements.
            {/* {isMonthly
              ? 'Founder Club gets a 50% discount across all tiers on monthly subscriptions. Best of all, that price is locked in forever.'
              : 'Get all your credits upfront and an extra 20% discount with annual subscriptions. Founders club members get to lock in that price forever.'} */}
          </Text>
        </Flex>

        <Flex justifyContent="center" display={{ base: 'none', lg: 'flex' }}>
          {/* Labels */}
          <Flex
            flexDir="column"
            gap="12px"
            display={{ base: 'none', lg: 'flex' }}
            fontFamily="Open Sans"
            fontWeight="700"
            fontSize="14px"
            lineHeight="16px"
            w="300px"
          >
            <Flex justifyContent="center" py="18px" px="32px" h="92px"></Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text>Send Contracts</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text>Receive offers</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text>Negotiate, Sign & Store Contracts</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text>Plain English Translation</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text>Affiliates Rewards Program</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text>Membership Discount</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text color="brand.primary">Access to:</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text ml="20px">Featured Artist Agreement</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text ml="20px">Producer Agreement</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text ml="20px">Mixer Agreement</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text ml="20px">Mastering Agreement</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text ml="20px">Pub Splits (Coming Soon)</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" h="16px">
              <Text ml="20px">Work-for-Hire Agreement (Coming Soon)</Text>
            </Flex>
          </Flex>

          {/* FREE */}
          <Flex flexDir="column" alignItems="center">
            <Box h="8px" w="90px" pb="16px" px="16px" mb="16px" ml="24px" />
            <Flex
              flexDir="column"
              gap="12px"
              bg="rgba(27, 28, 49, .5)"
              w={{ base: '100%', md: '168px' }}
              borderRadius="5px"
              pb="16px"
              px="16px"
              ml="24px"
            >
              <Flex justifyContent="center" py="18px" px="32px" h="60px">
                <Text
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="20px"
                  textAlign="center"
                >
                  Free
                </Text>
              </Flex>
              <Flex flexFlow="column" gap="12px" h="364px">
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Close color="#FA4B4B" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Close color="#FA4B4B" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Close color="#FA4B4B" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                ></Flex>

                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text alignItems="center" fontSize="12px" fontWeight="700">
                    -
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text alignItems="center" fontSize="12px" fontWeight="700">
                    -
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text alignItems="center" fontSize="12px" fontWeight="700">
                    -
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text alignItems="center" fontSize="12px" fontWeight="700">
                    -
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text alignItems="center" fontSize="12px" fontWeight="700">
                    -
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text alignItems="center" fontSize="12px" fontWeight="700">
                    -
                  </Text>
                </Flex>
              </Flex>

              <Divider />

              <Flex flexDir="column" justifyContent="space-between" gap="16px" h="100px">
                <Flex
                  flexDir="column"
                  gap="4px"
                  justifyContent="center"
                  flex="1"
                >
                  <Heading textAlign="center">FREE</Heading>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {/* BEGINNER */}
          <Flex flexDir="column" alignItems="center">
            <Box
              h="8px"
              w="90px"
              pb="16px"
              px="16px"
              mb="16px"
              ml="24px"
              mr="8px"
            />
            <Flex
              justifyContent="space-between"
              flexDir="column"
              gap="12px"
              bg="brand.secondary2"
              w={{ base: '100%', md: '168px' }}
              borderRadius="5px"
              pb="16px"
              px="16px"
              ml="24px"
              mr="8px"
            >
              <Flex justifyContent="center" py="18px" px="32px" h="60px">
                <Text
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="20px"
                  textAlign="center"
                >
                  Beginner
                  {currentPlan?.type ===
                    (isMonthly
                      ? MembershipType.BASIC_MONTH
                      : MembershipType.BASIC_ANNUAL) && (
                    <>
                      <Text
                        fontWeight="700"
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                      >
                        (current)
                      </Text>
                    </>
                  )}
                </Text>
              </Flex>
              <Flex flexFlow="column" gap="12px" h="364px">
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text fontWeight="700" fontSize="20px" lineHeight="96px">
                    1
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Close color="#FA4B4B" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Close color="#FA4B4B" />
                </Flex>

                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                ></Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
              </Flex>

              <Divider />
              <Flex flexDir="column" justifyContent="space-between" gap="16px" h="100px">
                <Text
                  fontFamily="Poppins"
                  fontWeight="700"
                  fontSize="18px"
                  textAlign="center"
                  color="brand.primary"
                >
                  $30/contract
                </Text>

                <Flex justifyContent="center" width="100%">
                  <Button
                    variant="outline"
                    width="100%"
                    // disabled={
                    //   isMonthly
                    //     ? currentPlan?.type === MembershipType.BASIC_MONTH
                    //     : currentPlan?.type === MembershipType.BASIC_ANNUAL
                    // }
                    onClick={() => handleSelectClick(MembershipType.BEGINNER)}
                    color="#7BFF66"
                  >
                    Select
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {/* INTERMEDIATE */}
          <Flex flexDir="column" alignItems="center">
            <Box
              h="8px"
              w="60px"
              pb="16px"
              px="16px"
              mr="8px"
              mb="16px"
              borderLeft="1px solid #fff"
              borderTop="1px solid #fff"
              alignSelf="flex-start"
            />

            <Flex
              justifyContent="space-between"
              flexDir="column"
              gap="12px"
              bg="brand.secondary2"
              w={{ base: '100%', md: '168px' }}
              borderRadius="5px"
              pb="16px"
              px="16px"
              mr="8px"
            >
              <Flex justifyContent="center" py="18px" px="32px" h="60px">
                <Text
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="20px"
                  textAlign="center"
                >
                  Intermediate
                  {currentPlan?.type ===
                    (isMonthly
                      ? MembershipType.PLUS_MONTH
                      : MembershipType.PLUS_ANNUAL) && (
                    <>
                      <Text
                        fontWeight="700"
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                      >
                        (current)
                      </Text>
                    </>
                  )}
                </Text>
              </Flex>
              <Flex flexFlow="column" gap="12px" h="364px">
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text fontWeight="700" fontSize="20px" lineHeight="96px">
                    {isMonthly ? 2 : 24}
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text color="#7BFF66" fontWeight="700" fontSize="16px">
                    {isMonthly ? '17%' : '30%'}
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                ></Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
              </Flex>
              <Divider />

              <Flex flexDir="column" justifyContent="space-between" gap="16px" h="100px">
                <Text
                  fontFamily="Poppins"
                  fontWeight="700"
                  fontSize="18px"
                  textAlign="center"
                  color="brand.primary"
                >
                  {isMonthly ? '$50/month' : '$500/year'}
                </Text>

                <Flex justifyContent="center" width="100%">
                  <Button
                    variant="outline"
                    disabled={
                      shouldPlanBeDisabledMap[
                        isMonthly
                          ? MembershipType.PLUS_MONTH.toString()
                          : MembershipType.PLUS_ANNUAL.toString()
                      ]
                    }
                    color="#7BFF66"
                    width="100%"
                    onClick={() =>
                      handleSelectClick(
                        isMonthly
                          ? MembershipType.PLUS_MONTH
                          : MembershipType.PLUS_ANNUAL
                      )
                    }
                  >
                    Select
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {/* PRO */}
          <Flex flexDir="column" alignItems="center" position="relative" h="100px">
            <Flex
              flexFlow="column"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top="-45px"
              right="68px"
            >
              <Text
                fontFamily="Open Sans"
                fontWeight="700"
                fontSize="20px"
                lineHeight="24px"
                color="brand.primary"
                textAlign="center"
                w={{ base: '185px', lg: '208px' }}
              >
                Charta Membership
              </Text>
              <Text textAlign="center" mt="5px">
                <Flex
                  bg="rgba(27, 28, 49, 0.5)"
                  borderRadius="8px"
                  height="32px"
                  p="4px"
                  w="202px"
                  gap="2px"
                >
                  <Button
                    variant={!isMonthly ? 'ghost' : 'normal'}
                    onClick={() => setIsMonthly(true)}
                    h="24px"
                    w="97px"
                    fontSize="12px"
                  >
                    Monthly
                  </Button>
                  <Button
                    variant={!isMonthly ? 'normal' : 'ghost'}
                    onClick={() => setIsMonthly(false)}
                    h="24px"
                    w="97px"
                    fontSize="12px"
                  >
                    Annual
                  </Button>
                </Flex>
              </Text>
            </Flex>
            <Box
              h="8px"
              w="60px"
              pb="16px"
              px="16px"
              mb="16px"
              borderRight="1px solid #fff"
              borderTop="1px solid #fff"
              alignSelf="flex-end"
            />
            <Flex
              justifyContent="space-between"
              flexDir="column"
              gap="12px"
              bg="brand.secondary2"
              w={{ base: '100%', md: '168px' }}
              borderRadius="5px"
              pb="16px"
              px="16px"
            >
              <Flex justifyContent="center" py="18px" px="32px" h="60px">
                <Text
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="20px"
                  textAlign="center"
                >
                  Pro
                  {currentPlan?.type ===
                    (isMonthly
                      ? MembershipType.PRO_MONTH
                      : MembershipType.PRO_ANNUAL) && (
                    <>
                      <Text
                        fontWeight="700"
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                      >
                        (current)
                      </Text>
                    </>
                  )}
                </Text>
              </Flex>
              <Flex flexFlow="column" gap="12px" h="364px">
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text fontWeight="700" fontSize="20px" lineHeight="96px">
                    {isMonthly ? 3 : 36}
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Text color="#7BFF66" fontWeight="700" fontSize="16px">
                    {isMonthly ? '33%' : '40%'}
                  </Text>
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                ></Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
                <Flex
                  justifyContent={{ base: 'space-between', lg: 'center' }}
                  alignItems="center"
                  h="16px"
                >
                  <Tick color="#7BFF66" />
                </Flex>
              </Flex>

              <Divider />

              <Flex flexDir="column" justifyContent="space-between" gap="16px" h="100px">
                <Text
                  fontFamily="Poppins"
                  fontWeight="700"
                  fontSize="18px"
                  textAlign="center"
                  color="brand.primary"
                >
                  {isMonthly ? '$60/month' : '$650/year'}
                </Text>

                <Flex justifyContent="center" width="100%">
                  <Button
                    variant="outline"
                    width="100%"
                    disabled={
                      shouldPlanBeDisabledMap[
                        isMonthly
                          ? MembershipType.PRO_MONTH.toString()
                          : MembershipType.PRO_ANNUAL.toString()
                      ]
                    }
                    color="#7BFF66"
                    onClick={() =>
                      handleSelectClick(
                        isMonthly
                          ? MembershipType.PRO_MONTH
                          : MembershipType.PRO_ANNUAL
                      )
                    }
                  >
                    Select
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex display={{ base: 'flex', lg: 'none' }} flexFlow="column">
          {displayMobileBasicPaymentPlan}
          <Flex
            flexFlow="column"
            alignItems="center"
            justifyContent="center"
            mt="36px"
            mb="24px"
          >
            <Flex justifyContent="space-between" width="100%">
              <Box width="70px" borderTop="1px solid #fff" />
              <Text
                fontFamily="Open Sans"
                fontWeight="700"
                fontSize="16px"
                lineHeight="24px"
                color="brand.primary"
                textAlign="center"
                w={{ base: '185px', lg: '208px' }}
                position="relative"
                top="-12px"
              >
                Memberships
              </Text>

              <Box width="70px" borderTop="1px solid #fff" />
            </Flex>

            <Text textAlign="center" mt="0px">
              <Flex
                bg="rgba(27, 28, 49, 0.5)"
                borderRadius="8px"
                height="32px"
                p="4px"
                w="202px"
                gap="2px"
              >
                <Button
                  variant={!isMonthly ? 'ghost' : 'normal'}
                  onClick={() => setIsMonthly(true)}
                  h="24px"
                  w="97px"
                  fontSize="12px"
                >
                  Monthly
                </Button>
                <Button
                  variant={!isMonthly ? 'normal' : 'ghost'}
                  onClick={() => setIsMonthly(false)}
                  h="24px"
                  w="97px"
                  fontSize="12px"
                >
                  Annual
                </Button>
              </Flex>
            </Text>
          </Flex>
          {displayMobilePaymentPlans}
        </Flex>

        <Flex>
          <Flex w="100%" maxWidth="800px" flexDir="column">
            <Text
              mx={{ base: 0, lg: '25px' }}
              fontSize="12px"
              mt={{ base: '24px', lg: '24px' }}
            >
              *Founder's Club rates are locked in forever with continuous
              membership.
            </Text>
            <Text mx={{ base: 0, lg: '25px' }} fontSize="12px">
              **Annual members get all their credits upfront.
            </Text>
          </Flex>
          <Flex
            w="100%"
            maxWidth="208px"
            display={{ base: 'none', lg: 'initial' }}
          />
          <Flex
            w="100%"
            maxWidth="220px"
            display={{ base: 'none', lg: 'initial' }}
          />
        </Flex>
      </Flex>
    </>
  );
};
