import React, { useEffect } from 'react';
import { Flex, Heading, Image, Box, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { navbarColorAtom } from 'src/atoms';
import { useAtom } from 'jotai';
import { chartaProcessTheme } from 'src/theme/theme';

export const ChartaProcess = () => {
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const navigate = useNavigate();

  useEffect(() => {
    setNavbarColor('#1A202C');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDir="column" backgroundColor={chartaProcessTheme.background}>
      <Heading
        fontFamily="Poppins"
        fontWeight="400"
        fontSize={{ base: '42px', lg: '104px' }}
        lineHeight={{ base: '42px', lg: '124.8px' }}
        textAlign="center"
        color={chartaProcessTheme.headingTextColor}
      >
        HOW DOES IT WORK?
      </Heading>
      <Flex
        flexGrow="1"
        mt="25px"
        justifyContent="center"
        flexDir={{ base: 'column', lg: 'row' }}
        gap="48px"
      >
        <Flex
          bg={chartaProcessTheme.processColor}
          width={{ base: '100%', lg: '360px' }}
          flexDir="column"
        >
          <Image src="./create.png" />
          <Box height="224px" bg={chartaProcessTheme.processColor} px="20px">
            <Text
              textAlign="center"
              fontSize="24px"
              my="30px"
              lineHeight="28.8px"
              fontWeight="700"
            >
              1. Create your offers
            </Text>
            <Text textAlign="center" fontSize="16px" fontWeight="400">
              Each person will go through our quick, plain English questionnaire
              to put together their initial offer.
            </Text>
          </Box>
        </Flex>

        <Flex
          bg={chartaProcessTheme.processColor}
          width={{ base: '100%', lg: '360px' }}
          flexDir="column"
        >
          <Image src="./negotiate.png" />
          <Box height="224px" bg={chartaProcessTheme.processColor} px="20px">
            <Text
              my="30px"
              fontSize="24px"
              lineHeight="28.8px"
              fontWeight="700"
              textAlign="center"
            >
              2. Negotiate the terms
            </Text>
            <Text textAlign="center" fontSize="16px" fontWeight="400">
              Once we have both of your initial offers, we'll show you what
              terms you don't agree on and let you negotiate directly with each
              other.
            </Text>
          </Box>
        </Flex>

        <Flex
          bg={chartaProcessTheme.processColor}
          width={{ base: '100%', lg: '360px' }}
          flexDir="column"
        >
          <Image src="./sign_2.png" />
          <Box height="224px" bg={chartaProcessTheme.processColor} px="20px">
            <Text
              textAlign="center"
              my="30px"
              fontSize="24px"
              lineHeight="28.8px"
              fontWeight="700"
            >
              3. Sign the contract
            </Text>
            <Text textAlign="center" fontSize="16px" fontWeight="400">
              After you've agreed on all the terms, Charta will automatically
              generate a custom agreement that each person can sign and store in
              their dashboard.
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent="center" my="20px" mt="72px">
        <Button
          size="lg"
          onClick={() => navigate('/pre-step-through')}
          data-heap-id="charta-process.continue"
          color={chartaProcessTheme.buttonTextColor}
          bg={chartaProcessTheme.buttonColor}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};
