import React, { useEffect, useState } from 'react';
import { Flex, Box, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { Footer } from '../components/core/Footer';
import { EnterEmail } from '../components/password-reset/EnterEmail';
import { useAtom } from 'jotai';
import { userAtom, navbarColorAtom } from 'src/atoms';
import { useNavigate } from 'react-router-dom';
import { EnterCode } from 'src/components/password-reset/EnterCode';
import { EnterPassword } from 'src/components/password-reset/EnterPassword';
import { ResetConfirmation } from 'src/components/password-reset/ResetConfirmation';
import { ChartaBack } from 'src/components/core/ChartaBack';
import {
  completePasswordReset,
  startPasswordReset,
  verifyPassword
} from 'src/api';
import { passwordResetTheme } from 'src/theme/theme';

export interface PasswordResetProps extends FlexProps {}
type ResetPasswordView = 'email' | 'code' | 'password' | 'confirmation';
export const PasswordReset: React.FC<PasswordResetProps> = ({ ...props }) => {
  const [user] = useAtom(userAtom);
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const navigate = useNavigate();
  const [resetState, setResetState] = useState<{
    view: ResetPasswordView;
    email: string;
    resetCode: string;
    password: string;
    confirmPassword: string;
    _id: string;
  }>({
    view: 'email',
    email: '',
    resetCode: '',
    password: '',
    confirmPassword: '',
    _id: ''
  });

  const bg = passwordResetTheme.modalBackground;
  const color = useColorModeValue('#1B1C31', 'brand.secondary1');

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailEntered = async (email: string) => {
    await startPasswordReset({ email });

    setResetState({
      view: 'code',
      email,
      resetCode: '',
      password: '',
      confirmPassword: '',
      _id: ''
    });
  };

  const handleCodeRequested = async () => {
    await startPasswordReset({ email: resetState.email });
  };

  const handleCodeEntered = async (resetCode: string) => {
    const {
      data: { _id }
    } = await verifyPassword({ resetCode });

    setResetState((prev) => ({
      ...prev,
      resetCode,
      view: 'password',
      _id
    }));
  };

  const handlePasswordEntered = async (
    password: string,
    confirmPassword: string
  ) => {
    await completePasswordReset({
      ...resetState,
      password,
      confirmPassword
    });

    setResetState((prev) => ({
      ...prev,
      view: 'confirmation'
    }));
  };

  return (
    <Flex
      bgColor={passwordResetTheme.background}
      overflowY="hidden"
      flexDirection="column"
      justifyContent="space-between"
      h="100vh"
      overflow="hidden"
      {...props}
    >
      <Flex
        flexGrow="1"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          bg={bg}
          borderRadius="5px"
          h={{ base: '100%', lg: 'auto' }}
          minWidth={{ base: '100%', lg: '450px' }}
          flexDirection="column"
          alignItems="center"
          color={color}
          position="relative"
        >
          <ChartaBack
            label="Go back"
            position="absolute"
            top="0"
            left="-120px"
            onClick={() => navigate('/')}
          />
          {resetState.view === 'email' ? (
            <EnterEmail onEmailEntered={handleEmailEntered} />
          ) : resetState.view === 'code' ? (
            <EnterCode
              onCodeEntered={handleCodeEntered}
              onCodeRequested={handleCodeRequested}
            />
          ) : resetState.view === 'password' ? (
            <EnterPassword onPasswordEntered={handlePasswordEntered} />
          ) : resetState.view === 'confirmation' ? (
            <ResetConfirmation />
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};
