import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { radioAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderRadius: "3px",
    height: "24px",
    width: "24px",
    borderColor: "white",
    borderWidth: "1px",
    _hover: {
      background: "#7BFF66",
      borderColor: "#7BFF66",
    },
    _checked: {
      borderColor: "#7BFF66",
      background: "#7BFF66",
      _before: null,
      _hover: {
        borderColor: "#7BFF66",
        background: "#7BFF66",
      },
    },

    _dark: {
      borderColor: "white",
      _checked: {
        borderColor: "transparent",
        background: "#7BFF66",
        _before: null,
        _hover: {
          borderColor: "#7BFF66",
          background: "#7BFF66",
        },
      },
      _hover: {
        background: "#7BFF66",
        borderColor: "#7BFF66",
      },
      _invalid: {
        background: "transparent",
        borderColor: "red.300",
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
