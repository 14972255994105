import {
  Heading,
  useColorModeValue,
  FlexProps,
  Flex,
  FormControl,
  Input,
  Button,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

export interface EnterPasswordProps extends FlexProps {
  onPasswordEntered: (password: string, confirmPassword: string) => void;
}

export const EnterPassword: React.FC<EnterPasswordProps> = ({
  onPasswordEntered,
  ...props
}) => {
  const color = useColorModeValue("#1B1C31", "brand.secondary1");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});

  const onSubmit = (values: any) => {
    onPasswordEntered(values.password, values.confirmPassword);
  };

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      p="32px"
      as="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Heading fontSize="56px" fontWeight="400" lineHeight="50.4px">
        Reset Password
      </Heading>
      <FormControl mt="20px" w={{ base: "100%", lg: "70%" }} isInvalid={!!errors.password}>
        <FormLabel>New password</FormLabel>
        <Input
          borderColor={color}
          type="password"
          {...register("password", { required: true })}
        />
        <FormErrorMessage>Password is required</FormErrorMessage>
      </FormControl>
      <FormControl mt="20px" w={{ base: "100%", lg: "70%" }} isInvalid={!!errors.confirmPassword}>
        <FormLabel>Confirm password</FormLabel>
        <Input
          borderColor={color}
          type="password"
          {...register("confirmPassword", {
            required: true,
            validate: (value: string) => {
              if (watch("password") !== value) {
                return "Your passwords do no match";
              }
            },
          })}
        />
        <FormErrorMessage>
          {errors.confirmPassword?.type === "required"
            ? "Password confirmation required"
            : "Passwords do not match"}
        </FormErrorMessage>
      </FormControl>
      <Button mt="24px" w="138px" h="40px" variant="gradient" type="submit">
        Submit
      </Button>
    </Flex>
  );
};
