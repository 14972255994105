import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Text
} from '@chakra-ui/react';
import { NavBar } from 'src/components/core/NavBar';
import { ChartaBack } from 'src/components/core/ChartaBack';
import { ViewContract } from 'src/components/contract/ViewContract';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';
import { userAtom, navbarColorAtom } from 'src/atoms';
import { Close } from 'src/components/icons/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { getContractById, signContract } from 'src/api';
import { Contract as IContract } from 'src/interfaces';
import { LoadScreen } from 'src/components/core/LoadScreen';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { contractTheme } from 'src/theme/theme';

export const Contract = () => {
  const bg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );
  const actionBg = useColorModeValue('brand.secondary1', 'brand.secondary2');

  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFontIndex, setSelectedFontIndex] = useState<number>(0);
  const [user] = useAtom(userAtom);
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const { showLoading, showServerError, hideLoading } = useApiHelpers();
  const { register, getValues, watch } = useForm({
    defaultValues: {
      fullName: `${user?.firstName} ${user?.lastName}`,
      initials: ''
    }
  });
  const [contractData, setContractData] = useState<{
    contract: IContract;
    urls: { lightUrls: string[]; darkUrls: string[] };
  } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getContract = async () => {
      const {
        data: { contract, urls }
      } = await getContractById(id!);

      setContractData({
        contract,
        urls
      });
    };

    if (id) {
      try {
        showLoading();
        getContract();
      } catch (error) {
        hideLoading();
        showServerError();
      } finally {
        hideLoading();
      }
    }
  }, [id]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  watch();

  const fonts = [
    { fontFamily: 'Just Me Again Down Here' },
    { fontFamily: 'Kolker Brush' },
    { fontFamily: 'La Belle Aurore' },
    { fontFamily: 'Open Sans', fontStyle: 'Italic', fontWeight: '700' }
  ];

  const handleOnSign = async () => {
    onClose();
    const darkImage = getBase64ImageString('#FFFFFF');
    const lightImage = getBase64ImageString('#000000');

    try {
      showLoading();
      const {
        data: { contract, urls }
      } = await signContract({
        contractId: contractData!.contract._id,
        lightSignature: lightImage,
        darkSignature: darkImage,
        name: getValues('fullName')
      });

      setContractData({
        contract,
        urls
      });
    } catch (error) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
  };

  const getBase64ImageString = (color: string): string => {
    const { fontFamily, fontStyle } = fonts[selectedFontIndex];
    const name = getValues('fullName');

    const canvas = document.createElement('canvas');
    canvas.height = 80;
    canvas.width = name.length * 40;
    const ctx = canvas.getContext('2d');
    if (fontStyle) {
      ctx!.font = `${fontStyle} 48px ${fontFamily}`;
    } else {
      ctx!.font = `48px ${fontFamily}`;
    }
    ctx!.fillStyle = color;
    ctx!.fillText(name, 0, 60);

    return canvas.toDataURL('image/png');
  };

  const handleOnBack = async () => {
    navigate('/dashboard');
  };

  if (!contractData) {
    return <LoadScreen />;
  }

  return (
    <Box
      background={contractTheme.background}
      h="100vh"
      position="relative"
      overflow="hidden"
      // _before={{
      //   content: "''",
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100%',
      //   bgImage: 'url(/a.png)',
      //   bgRepeat: 'no-repeat',
      //   bgPosition: '110% 50%',
      //   bgSize: '50%'
      // }}
    >
      <Flex
        h="100vh"
        overflowY="hidden"
        flexDirection="column"
        position="relative"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          background={contractTheme.background}
          padding={{ base: '8px 16px', lg: '19px 24px' }}
        >
          <ChartaBack
            label="Back"
            onClick={() => handleOnBack()}
            color={contractTheme.backButtonColor}
          />
          {((user?.email === contractData?.contract.initiatorEmail &&
            !contractData.contract.initiatorSignedAt) ||
            (user?.email === contractData?.contract.receiverEmail &&
              !contractData.contract.receiverSignedAt)) && (
            <Button
              onClick={() => onOpen()}
              data-heap-id="sign-contract"
              color={contractTheme.buttonTextColor}
              bg={contractTheme.buttonColor}
            >
              Sign full contract
            </Button>
          )}
        </Flex>

        <ViewContract
          contract={contractData.contract}
          lightUrls={contractData.urls.lightUrls}
          darkUrls={contractData.urls.darkUrls}
          marginTop={{ base: '8px', lg: '14px' }}
          paddingX={{ base: '8px', lg: '58px' }}
        />
      </Flex>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', lg: '6xl' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          position="relative"
          height="90%"
          overflowY="auto"
          bg={contractTheme.modalBackground}
          // bg={'red'}
        >
          <Close
            _hover={{ cursor: 'pointer' }}
            h="10px"
            w="10px"
            color="brand.primary"
            onClick={onClose}
            position="absolute"
            top="10px"
            right="10px"
          />
          <Flex height="100%">
            <Box
              flex={1}
              bg={contractTheme.sideBackground}
              display={{ base: 'none', lg: 'initial' }}
            >
              {' '}
              <img
                src="/um.png"
                style={{
                  maxWidth: '300px',
                  display: 'block',
                  margin: '0px auto',
                  position: 'relative',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
              ></img>
            </Box>

            <Flex flex={2} flexDir="column">
              <Flex
                flexDir="column"
                padding={{ base: '8px 8px', lg: '24px 24px' }}
                gap="16px"
              >
                <Heading
                  fontWeight="400"
                  fontSize="56px"
                  lineHeight="56px"
                  color={contractTheme.headingTextColor}
                >
                  Sign Contract
                </Heading>
                <Flex gap="48px">
                  <FormControl flex={3}>
                    <FormLabel color={contractTheme.textColor}>
                      Full name
                    </FormLabel>
                    <Input
                      placeholder="Full name"
                      {...register('fullName', { required: true })}
                      color={contractTheme.textColor}
                    />
                  </FormControl>
                  <FormControl flex={1}>
                    <FormLabel color={contractTheme.textColor}>
                      Initials
                    </FormLabel>
                    <Input
                      placeholder="Ex: JL"
                      value={getValues('fullName')
                        ?.split(' ')
                        .map((n) => n[0])
                        .join('')}
                      disabled
                      color={contractTheme.textColor}
                    />
                  </FormControl>
                </Flex>
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="14px"
                  color={contractTheme.textColor}
                >
                  Select style
                </Text>
              </Flex>

              <Flex flexDir="column" paddingX={{ base: '8px', lg: '24px' }}>
                <hr
                  style={{
                    marginTop: '8px',
                    marginBottom: '16px',
                    borderTop: '1px solid brand.primary'
                  }}
                />
              </Flex>

              <Flex
                flexDir="column"
                paddingX={{ base: '8px', lg: '24px' }}
                gap="8px"
              >
                {fonts.map((font: any, key: number) => (
                  <Flex gap="48px" key={key}>
                    <Flex
                      width={{ base: '100%', lg: '60%' }}
                      padding={{ base: '4px 8px', lg: '8px 15px' }}
                      borderRadius="5px"
                      bg={contractTheme.fontBackground}
                      justifyContent="space-between"
                      alignItems="center"
                      border={
                        selectedFontIndex === key
                          ? '#7BFF66'
                          : ''
                      }
                      borderStyle={'solid'}
                      borderWidth={'1px'}
                      cursor="pointer"
                      onClick={() => setSelectedFontIndex(key)}
                    >
                      <Flex flexDir="column" gap="8px" flex={3}>
                        <Text
                          color="rgba(235, 235, 235, 0.45)"
                          fontSize="8px"
                          fontWeight="400"
                          lineHeight="12px"
                        >
                          Signed with Charta
                        </Text>
                        <Text
                          color={contractTheme.textColor}
                          fontSize="24px"
                          fontWeight="400"
                          lineHeight="36px"
                          h="26px"
                          maxW="220px"
                          noOfLines={1}
                          {...font}
                        >
                          {getValues('fullName')}
                        </Text>
                        <Text
                          color={contractTheme.textColor}
                          fontSize="8px"
                          fontWeight="400"
                          lineHeight="12px"
                        >
                          9384974349283749802342
                        </Text>
                      </Flex>
                      <Flex flexDir="column" gap="8px" flex={1}>
                        <Text
                          color={contractTheme.textColor}
                          fontSize="8px"
                          fontWeight="400"
                          lineHeight="12px"
                        >
                          Signed with Charta
                        </Text>
                        <Text
                          color={contractTheme.textColor}
                          fontSize="24px"
                          fontWeight="400"
                          lineHeight="36px"
                          h="26px"
                          {...font}
                        >
                          {getValues('fullName')
                            ?.split(' ')
                            .map((n) => n[0])
                            .join('')}
                        </Text>
                        <Text
                          color={contractTheme.textColor}
                          fontSize="8px"
                          fontWeight="400"
                          lineHeight="12px"
                        >
                          9384974349283749802342
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </Flex>

              <Flex justifyContent="flex-end" paddingX="24px">
                <Button
                  h="32px"
                  mt="20px"
                  onClick={() => handleOnSign()}
                  color={contractTheme.signContractButtonTextColor}
                  bgColor={contractTheme.signContractButtonBackgroundColor}
                >
                  Sign
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
};
