import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ExpandedIndex,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  Avatar
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { userAtom } from 'src/atoms';
import {
  Answer,
  ContractType,
  Negotiation,
  NegotiationHistory,
  NegotiationPanelData,
  NegotiationStep,
  NegotiationTerm,
  RoleResponse
} from 'src/interfaces';
import { mergeAndSanitizeAnswers } from 'src/utils/mergeAndSanitizeAnswers';
import { parseAnswers } from 'src/utils/parseAnswers';
import { parseConditions } from 'src/utils/parseConditions';
import { ChartaProse } from 'src/components/core/ChartaProse';
import { ChartaText } from 'src/components/core/ChartaText';
import { ChartaField } from 'src/components/form/ChartaField';
import { Check } from 'src/components/icons/Check';
import { Dropdown } from 'src/components/icons/Dropdown';
import { Info } from 'src/components/icons/Info';
import { Notification } from 'src/components/icons/Notification';
import cloneDeep from 'lodash/cloneDeep';
import { addCommasToNumber } from '../../utils/addCommasToNumber';
import { negotiationPanelTheme } from 'src/theme/theme';

export interface NegotiationPanelProps extends BoxProps {
  negotiation: Negotiation;
  onSubmitAnswers: (answers: Answer[]) => void;
  onViewContract: () => void;
}

interface AccordionState {
  index: number;
  steps: NegotiationStep[];
}

export const NegotiationPanel: React.FC<NegotiationPanelProps> = ({
  negotiation,
  onSubmitAnswers,
  onViewContract,
  ...props
}) => {
  const bg = negotiationPanelTheme.boxBackground;
  const termBackground = useColorModeValue(
    'rgba(255, 255, 255, 0.7)',
    '#12122D'
  );
  const headerColor = negotiationPanelTheme.headingTextColor;
  const highlightColor = negotiationPanelTheme.highlightColor;
  const textColor = useColorModeValue('#1B1C31', 'rgba(255, 255, 255, 0.7)');
  const notificationColor = useColorModeValue('#DE970D', '#F6C547');
  const successColor = useColorModeValue('#159700', '#7BFF66');
  const tableBorderColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const tableTextColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const panelDividerColor = useColorModeValue(
    'rgba(30, 32, 41, 0.8)',
    'rgba(255, 255, 255, 0.1)'
  );
  const offerColor = useColorModeValue('#DE970D', '#F6C547');

  const isContractSummary =
    negotiation.status !== 'active-contract' &&
    negotiation.status !== 'created';
  const [user] = useAtom(userAtom);
  const [newAnswers, setNewAnswers] = useState<Answer[]>([]);
  const [viewStatuses, setViewStatuses] = useState<Record<string, boolean>>({});
  const [historyStatuses, setHistoryStatuses] = useState<
    Record<string, boolean>
  >({});
  const [accordionState, setAccordionState] = useState<AccordionState>({
    index: -1,
    steps: []
  });
  const [tooltipState, setTooltipState] = useState<{
    header: string;
    content: string;
    isOpen: boolean;
  }>({
    header: '',
    content: '',
    isOpen: false
  });
  const [panelData, setPanelData] = useState<NegotiationPanelData>({
    subject: '',
    subjectName: '',
    steps: [],
    referenceAnswers: []
  });

  const termsNegotiated = panelData.steps
    .map((step) => step.counteredCount)
    .reduce((acc, curr) => acc + curr, 0);
  const termsRemaining =
    panelData.steps
      .map((step) => step.negotiatingCount)
      .reduce((acc, curr) => acc + curr, 0) - termsNegotiated;

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm();
  watch();

  const {
    initiator,
    receiver,
    latestInitiatorResponses,
    latestReceiverResponses,
    receiverResponses,
    initiatorResponses,
    createdAt,
    currentResponder,
    contractType
  } = negotiation;
  const {
    name: contractTypeName,
    form: { steps },
    conditions,
    validations,
    nonNegotiable,
    subjectType,
    subjectFieldName
  } = contractType as ContractType;
  const isUserInitiator = user!.email === initiator.email;
  let isUserResponding =
    (isUserInitiator && currentResponder === 'initiator') ||
    (!isUserInitiator && currentResponder === 'receiver');

  const getUserLatestAnswers = (): Answer[] => {
    return isUserInitiator
      ? latestInitiatorResponses.answers
      : latestReceiverResponses?.answers || [];
  };

  const getOtherPartyLatestAnswers = (): Answer[] => {
    return isUserInitiator
      ? latestReceiverResponses?.answers || []
      : latestInitiatorResponses.answers;
  };

  const getReferenceAnswers = (
    baseAnswers: Answer[],
    mainAnswers: Answer[]
  ): Answer[] => {
    const base = [...baseAnswers];
    const main = [...mainAnswers];

    for (let i = 0; i < main.length; i++) {
      const j = base.findIndex(
        (answer: Answer) => answer.fieldId === main[i].fieldId
      );
      if (j > -1) {
        base[j] = main[i];
      } else {
        base.push({ ...main[i] });
      }
    }
    return base;
  };

  const formatHistory = (
    response: RoleResponse,
    party: any,
    id: string
  ): NegotiationHistory | null => {
    if ((contractType as ContractType)?.oneSided) {
      return null;
    }
    const answer = response.answers.find(
      ({ questionId }: any) => questionId === id
    );

    if (answer) {
      return {
        name: `${party.firstName} ${party.lastName}`,
        role: party.role,
        imageUrl: party.profileImageUrl,
        value: answer.value,
        date: new Date(response.date).toLocaleDateString()
      };
    } else {
      return null;
    }
  };

  const getNegotiationTermHistory = (
    questionId: string
  ): NegotiationHistory[] => {
    let history: NegotiationHistory[] = [];

    const newResponse = {
      date: new Date().toDateString(),
      answers: [...newAnswers]
    };

    const newInitiatorResponses = [...initiatorResponses];
    const newReceiverResponses = [...receiverResponses];

    if (isUserInitiator) {
      newInitiatorResponses.push(newResponse);
    } else {
      newReceiverResponses.push(newResponse);
    }

    let initiatorDone = false;
    let receiverDone = false;
    let count = 0;

    while (!initiatorDone || !receiverDone) {
      if (count > newInitiatorResponses.length - 1) {
        initiatorDone = true;
      } else {
        const initiatorResponse = newInitiatorResponses[count];
        const initiatorAnswer = formatHistory(
          initiatorResponse,
          initiator,
          questionId
        );
        if (initiatorAnswer) {
          history.push(initiatorAnswer);
        }
      }

      if (count > newReceiverResponses.length - 1) {
        receiverDone = true;
      } else {
        const receiverResponse = newReceiverResponses[count];
        const receiverAnswer = formatHistory(
          receiverResponse,
          receiver,
          questionId
        );
        if (receiverAnswer) {
          history.push(receiverAnswer);
        }
      }
      count = count + 1;
    }
    return history;
  };
  const checkIfDummyField = (answer: any, contractType: any) => {
    if (
      contractType?.listOfFieldsToPlaceDummyDataIn != null &&
      Array.isArray(contractType?.listOfFieldsToPlaceDummyDataIn)
    ) {
      for (const dummyField of contractType.listOfFieldsToPlaceDummyDataIn) {
        if (answer.fieldId == dummyField.fieldId) {
          return true;
        }
      }
    }
    return false;
  };
  const addIsDummyToAnswerArray = (answers: any) => {
    return _cloneDeep(answers).map((answer: any) => {
      // return answer;
      const isDummyField = checkIfDummyField(answer, contractType);
      if (!isDummyField) {
        return answer;
      }
      return {
        ...answer,
        isDummyField
      };
    });
  };
  useEffect(() => {
    //Get user latest answers
    const answersToValidate = addIsDummyToAnswerArray(newAnswers);
    const latestAnswers = getUserLatestAnswers();
    const mappedLatestAnswers = addIsDummyToAnswerArray(latestAnswers);
    const userAnswers = mergeAndSanitizeAnswers(
      mappedLatestAnswers,
      answersToValidate,
      conditions
    );

    //Get other party latest answers
    const otherPartyAnswers = getOtherPartyLatestAnswers();

    //Reference answers is always in relation to current user?
    const referenceAnswers = getReferenceAnswers(
      otherPartyAnswers,
      userAnswers
    );

    //Initialize panelData object
    const newPanelData: NegotiationPanelData = {
      subject: subjectType,
      subjectName: parseAnswers(referenceAnswers)[subjectFieldName],
      steps: [],
      referenceAnswers: referenceAnswers
    };

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      newPanelData.steps.push({
        id: step.id,
        counteredCount: 0,
        negotiatingCount: 0,
        terms: []
      });

      for (let j = 0; j < step.subSteps.length; j++) {
        const subStep = step.subSteps[j];
        for (let k = 0; k < subStep.questions.length; k++) {
          const question = subStep.questions[k];

          //Skip this question/term if it is hidden
          if (question.hidden) {
            continue;
          }

          const isNonNegotiable = nonNegotiable.indexOf(question.field.id) > -1;

          //Skip this question/term if condition is not met
          const condition = conditions.questions?.find(
            (c: any) => c[question.id] !== undefined
          );
          if (
            !isNonNegotiable &&
            condition &&
            !parseConditions(condition, parseAnswers(referenceAnswers))
          ) {
            continue;
          }

          const userAnswer = userAnswers.find(
            (answer) => answer.questionId === question.id
          );
          const otherPartyAnswer = otherPartyAnswers.find(
            (answer) => answer.questionId === question.id
          );

          //Skip this question/term if neither party has answered
          if (!userAnswer?.value && !otherPartyAnswer?.value) {
            continue;
          }

          const term: NegotiationTerm = {
            step: step.id,
            subStep: subStep.id,
            question: question.id,
            fieldId: question.field.id,
            status: null,
            label: question.label,
            tooltip: question.tooltip,
            prefix: question.field.prefix,
            postfix: question.field.postfix,
            field: question.field //Hack to get field for counter-offer form control
          };

          const isZipCode = term.label === 'Zip Code'; //Hack to prevent commas from being added to Zip code

          const isCountered = userAnswer?.status === 'new';
          const isNegotiating = userAnswer?.value !== otherPartyAnswer?.value;
          const isFinal = userAnswer?.value === otherPartyAnswer?.value;

          //If this question is non-negotiable, then set term value to
          //initiator answer if it isn't null. Otherwise set term value to
          //receiver answer
          if (isNonNegotiable) {
            const initiatorValue = isUserInitiator
              ? userAnswer?.value
              : otherPartyAnswer?.value;
            const receiverValue = isUserInitiator
              ? otherPartyAnswer?.value
              : userAnswer?.value;

            let value =
              initiatorValue !== null ? initiatorValue : receiverValue;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'non-negotiable',
              value: addCommasToNumber(value, isZipCode)
            });
            continue;
          }

          if (isCountered) {
            newPanelData.steps[i].counteredCount++;
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'countered',
              userAnswer: addCommasToNumber(userAnswer.value, isZipCode),
              otherPartyAnswer: addCommasToNumber(
                otherPartyAnswer?.value,
                isZipCode
              ),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }

          if (isNegotiating) {
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'negotiating',
              userAnswer: addCommasToNumber(userAnswer?.value, isZipCode),
              otherPartyAnswer: addCommasToNumber(
                otherPartyAnswer?.value,
                isZipCode
              ),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }

          if (isFinal) {
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'final',
              value: addCommasToNumber(userAnswer?.value, isZipCode),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }
        }
      }
    }
    setPanelData(newPanelData);
  }, [negotiation, newAnswers]);

  useEffect(() => {
    setAccordionState((previous) => {
      if (previous.index === -1) {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      } else {
        return {
          index: 0,
          steps: panelData.steps.filter(
            (step) => step.id === previous.steps[0].id
          )
        };
      }
    });
    if (
      Object.keys(viewStatuses).length === 0 &&
      panelData?.steps?.length > 0 &&
      !isContractSummary
    ) {
      const temp = cloneDeep(viewStatuses);
      for (const step of panelData.steps) {
        temp[step.id] = true;
      }
      setViewStatuses(temp);
    }
  }, [panelData]);

  const onAcceptOffer = (term: NegotiationTerm) => {
    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value: term.otherPartyAnswer,
        status: 'new'
      }
    ]);
  };

  const onCounterOffer = async (term: NegotiationTerm) => {
    const result = await trigger(term.fieldId);

    if (!result) {
      return;
    }

    const value = getValues()[term.fieldId];
    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value: value,
        status: 'new'
      }
    ]);
  };

  const onEditCounterOffer = async (term: any) => {
    const answers = [...newAnswers].filter(
      (answer: Answer) => answer.fieldId !== term.fieldId
    );
    setNewAnswers(answers);
  };

  const onToggleViewStatus = (id: string) => {
    const status = viewStatuses[id];
    if (status === undefined) {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleHistoryStatus = (id: string) => {
    const status = historyStatuses[id];
    if (status === undefined) {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleAccordion = (index: ExpandedIndex) => {
    if (index === -1) {
      setAccordionState({
        index: -1,
        steps: [...panelData.steps]
      });
    } else {
      const filteredSteps = panelData.steps.filter(
        (step, i) => i === index.valueOf()
      );
      setAccordionState({
        index: 0,
        steps: [...filteredSteps]
      });
    }
  };

  const handleSubmit = async () => {
    onSubmitAnswers(newAnswers);
  };

  const onShowTooltip = (tooltip: any) => {
    setTooltipState({
      ...tooltip,
      isOpen: true
    });
  };

  const onCloseTooltip = () => {
    setTooltipState({
      header: '',
      content: '',
      isOpen: false
    });
  };

  const displaySteps = useMemo(
    () =>
      steps.map((step: any, index: number) => {
        const negotiationStep = accordionState.steps.find(
          (n) => n.id === step.id
        );

        if (!negotiationStep) {
          return <React.Fragment key={step.id}></React.Fragment>;
        }

        const displayNegotiationSteps = negotiationStep.terms
          .filter((term) => {
            if (!viewStatuses[negotiationStep.id]) {
              return true;
            }

            return term.status === 'negotiating' || term.status === 'countered';
          })
          .map((term) => (
            <Flex
              key={term.fieldId}
              bg={termBackground}
              padding="24px 24px 16px 24px"
              mb="12px"
              flexDirection="column"
              borderRadius="5px"
            >
              <Flex
                gap={{ base: '2px', lg: '8px' }}
                alignItems={{ base: 'flex-start', lg: 'center' }}
                cursor="pointer"
                mb="15px"
                flexDir={{ base: 'column', lg: 'row' }}
              >
                <ChartaText answers={panelData.referenceAnswers}>
                  {term.label}
                </ChartaText>
                <Flex
                  h="20.5px"
                  width="20.5px"
                  borderRadius="100%"
                  bg="linear-gradient(#FDE033, #EE4373)"
                  alignItems="center"
                  justifyContent="center"
                  hidden={!term.tooltip}
                  mt={{ base: '15px', lg: '0' }}
                  onClick={() => onShowTooltip(term.tooltip)}
                >
                  <Info color="#1B1C31" h="16px" w="18.5px" />
                </Flex>
              </Flex>

              {term.status === 'final' ? (
                <Flex gap="8px" alignItems="center">
                  <Text
                    color={successColor}
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                  >
                    Final term: {term.value}
                  </Text>
                  <Check color={successColor} h="16px" w="16px" />
                </Flex>
              ) : term.status === 'non-negotiable' ? (
                <Flex gap="8px" alignItems="center">
                  <Text
                    color={successColor}
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                  >
                    {term.value}
                  </Text>
                  <Check color={successColor} h="16px" w="16px" />
                </Flex>
              ) : term.status === 'countered' ? (
                <Flex justifyContent="space-between">
                  <Stack>
                    <Flex gap="8px" alignItems="center">
                      <Text
                        color={offerColor}
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="20px"
                      >
                        Your new offer: {term.prefix}
                        {term.userAnswer}
                        {term.postfix}
                      </Text>
                      <Notification
                        color={notificationColor}
                        h="16px"
                        w="16px"
                      />
                    </Flex>
                    <Flex alignItems="center">
                      <Text
                        color={textColor}
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="12px"
                      >
                        Their last offer:
                      </Text>
                      &nbsp;
                      <Text
                        color={textColor}
                        fontSize="12px"
                        fontWeight="700"
                        lineHeight="12px"
                      >
                        {term.prefix}
                        {term.otherPartyAnswer || '-'}
                        {term.postfix}
                      </Text>
                    </Flex>
                  </Stack>
                  <Button
                    onClick={() => onEditCounterOffer(term)}
                    hidden={!isUserResponding}
                    color={negotiationPanelTheme.buttonTextColor}
                  >
                    Edit
                  </Button>
                </Flex>
              ) : term.status === 'negotiating' ? (
                <Flex
                  justifyContent="space-between"
                  flexDir={{ base: 'column', lg: 'row' }}
                >
                  <Stack>
                    <Flex gap="8px">
                      <Text
                        color={offerColor}
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="20px"
                      >
                        Their offer: {term.prefix}
                        {term.otherPartyAnswer}
                        {term.postfix}
                      </Text>
                      <Notification
                        color={notificationColor}
                        h="16px"
                        w="16px"
                      />
                    </Flex>
                    <Flex>
                      <Text
                        color={textColor}
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="12px"
                      >
                        Your last offer:
                      </Text>
                      &nbsp;
                      <Text
                        color={textColor}
                        fontSize="12px"
                        fontWeight="700"
                        lineHeight="12px"
                      >
                        {term.prefix}
                        {term.userAnswer}
                        {term.postfix}
                      </Text>
                    </Flex>
                  </Stack>
                  {isUserResponding && (
                    <Flex
                      gap="20px"
                      alignItems="start"
                      justifyContent="end"
                      flexDir={{
                        base: 'column',
                        lg: 'row'
                      }}
                    >
                      <FormControl
                        key={term.fieldId}
                        isInvalid={!!errors[term.fieldId]}
                        w={{ base: '100%', lg: '40%' }}
                      >
                        <ChartaField
                          field={term.field}
                          register={register}
                          getValues={getValues}
                          setValue={setValue}
                          validations={validations}
                          control={control}
                          negotiation={true}
                        />
                        <FormErrorMessage>
                          {errors[term.fieldId]
                            ? term.field.customValidationMessage
                              ? term.field.customValidationMessage
                              : (errors[term.fieldId]?.message as string)
                            : ''}
                        </FormErrorMessage>
                      </FormControl>
                      <Flex
                        gap="8px"
                        justifyContent={{
                          base: 'center',
                          lg: 'flex-start'
                        }}
                        flexDir={{ base: 'column', lg: 'row' }}
                      >
                        <Button
                          onClick={() => onCounterOffer(term)}
                          bgColor={
                            negotiationPanelTheme.counterOfferBackgroundColor
                          }
                        >
                          Make Counteroffer
                        </Button>
                        <Button
                          variant="transparent"
                          disabled={term?.otherPartyAnswer == 'pending'}
                          onClick={() => onAcceptOffer(term)}
                          color={negotiationPanelTheme.green}
                        >
                          Accept Offer
                        </Button>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              ) : (
                <>Invalid Status: {term.status}</>
              )}
              {!(contractType as ContractType)?.oneSided && (
                <Flex
                  gap="9px"
                  alignItems="center"
                  mt="16px"
                  mb="7px"
                  onClick={() => onToggleHistoryStatus(term.fieldId)}
                  _hover={{ cursor: 'pointer' }}
                  display={term.status === 'non-negotiable' ? 'none' : 'flex'}
                >
                  <Text
                    color={negotiationPanelTheme.green}
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="16.34px"
                  >
                    Negotiation History
                  </Text>
                  <Dropdown
                    color={negotiationPanelTheme.green}
                    h="4px"
                    w="8px"
                    sx={
                      historyStatuses[term.fieldId]
                        ? {
                            transform: 'rotate(180deg)'
                          }
                        : {}
                    }
                  />
                </Flex>
              )}
              {!(contractType as ContractType)?.oneSided &&
                historyStatuses[term.fieldId] && (
                  <>
                    {term.history?.map((answer: any, index: number) => (
                      <Flex
                        key={index}
                        alignItems="center"
                        justifyContent="flex-start"
                        borderBottom="solid 0.5px"
                        borderColor={tableBorderColor}
                        py="8px"
                        borderTop={index === 0 ? 'solid 1px' : ''}
                      >
                        <Flex gap="12px" alignItems="center" flex="1 1 0">
                          <Avatar
                            src={answer.imageUrl}
                            size="sm"
                            display={{
                              base: 'none',
                              lg: 'initial'
                            }}
                          />
                          <Flex flexDirection="column">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight="16px"
                              color={tableTextColor}
                            >
                              {answer.name}
                            </Text>
                            <Text
                              fontSize="10px"
                              fontWeight="700"
                              lineHeight="12px"
                              color={tableTextColor}
                            >
                              {answer.role}
                            </Text>
                          </Flex>
                        </Flex>
                        <Text
                          flex="1 1 0"
                          fontSize="12px"
                          fontWeight="700"
                          lineHeight="16px"
                          color={tableTextColor}
                          align={{
                            base: 'center',
                            lg: 'left'
                          }}
                        >
                          {addCommasToNumber(
                            answer.value,
                            term.label === 'Zip Code'
                          )}
                        </Text>
                        <Text
                          flex="1 1 0"
                          fontSize="12px"
                          fontWeight="400"
                          lineHeight="16px"
                          color={tableTextColor}
                          align={{
                            base: 'right',
                            lg: 'left'
                          }}
                        >
                          {answer.date}
                        </Text>
                      </Flex>
                    ))}
                  </>
                )}
            </Flex>
          ));

        return (
          <AccordionItem key={step.id}>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Flex justifyContent="space-between" w="100%">
                    <Text
                      fontSize="24px"
                      fontWeight="600"
                      lineHeight="32.68px"
                      color={highlightColor}
                    >
                      {step.title}
                    </Text>
                    {negotiationStep.negotiatingCount === 0 ? (
                      <Flex gap="8px" alignItems="center">
                        <Check color={successColor} h="16px" w="16px" />
                        <Text
                          color={successColor}
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="600"
                          mr="8px"
                        >
                          All approved
                        </Text>
                      </Flex>
                    ) : (
                      <Flex gap="8px" alignItems="center">
                        <Notification
                          color={notificationColor}
                          h="16px"
                          w="16px"
                        />
                        <Text
                          color={notificationColor}
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="600"
                          mr="8px"
                        >
                          {negotiationStep.negotiatingCount -
                            negotiationStep.counteredCount}{' '}
                          terms remaining
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Dropdown
                    color={highlightColor}
                    h="4px"
                    w="12px"
                    sx={
                      isExpanded
                        ? {
                            transform: 'rotate(180deg)'
                          }
                        : {}
                    }
                  />
                </AccordionButton>

                <AccordionPanel>
                  <Divider
                    orientation="horizontal"
                    border="1px solid"
                    color={panelDividerColor}
                  />
                  {!isContractSummary && (
                    <Flex py="8px" alignItems="center" gap="6px">
                      <Text
                        fontSize="12px"
                        lineHeight="14.4px"
                        fontWeight={
                          !viewStatuses[negotiationStep.id] ? '700' : '400'
                        }
                        color={
                          !viewStatuses[negotiationStep.id]
                            ? negotiationPanelTheme.green
                            : textColor
                        }
                      >
                        All
                      </Text>
                      <Switch
                        onChange={() => onToggleViewStatus(step.id)}
                        defaultChecked={viewStatuses[step.id]}
                        color={negotiationPanelTheme.green}
                      />
                      <Text
                        fontSize="12px"
                        lineHeight="14.4px"
                        fontWeight={
                          viewStatuses[negotiationStep.id] ? '700' : '400'
                        }
                        color={
                          viewStatuses[negotiationStep.id]
                            ? negotiationPanelTheme.green
                            : textColor
                        }
                      >
                        Negotiating
                      </Text>
                    </Flex>
                  )}
                  <Box h={{ base: '90%' }} overflowY="auto">
                    {displayNegotiationSteps}
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      accordionState.steps,
      errors,
      historyStatuses,
      isContractSummary,
      isUserResponding,
      panelData.referenceAnswers,
      steps,
      validations,
      viewStatuses
    ]
  );

  return (
    <Flex
      flexDirection="column"
      as="form"
      noValidate
      padding={{ base: '8px 8px 8px 8px', xl: '32px 32px 32px 32px' }}
      borderRadius={{ base: '0', xl: '8px' }}
      sx={{ backdropFilter: 'blur(120px)' }}
      filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
      bg={bg}
      // bg={'red'}
      mx={{ base: '0px', lg: '100px' }}
      h={{ base: '100%', lg: '80%' }}
      overflowY="auto"
      flex="1"
      {...props}
    >
      <Flex
        justifyContent="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Flex flexDirection="column">
            <Heading
              as="h1"
              fontFamily="Poppins"
              fontSize="56px"
              lineHeight="68.4px"
              fontWeight="400"
              mb="6px"
              color={headerColor}
            >
              {panelData.subject}: '{panelData.subjectName}'
            </Heading>
            <Text
              fontFamily="Open Sans"
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
              mb="12px"
              color={headerColor}
            >
              Contract started:
              <b>{` "${moment(createdAt).format('MMM DD, YYYY')}"`}</b>
            </Text>
          </Flex>
          <Flex alignItems="center" justifyItems={'end'} gap="10px" mb="16px">
            <Text
              fontFamily="Open Sans"
              fontSize="40px"
              lineHeight="16px"
              fontWeight="700"
              color={headerColor}
            >
              {termsRemaining < 10 ? `0${termsRemaining}` : termsRemaining}
            </Text>
            <Text fontFamily="Open Sans" color={headerColor}>
              Terms
              <br />
              remaining
            </Text>
            <Text
              fontFamily="Open Sans"
              fontSize="40px"
              lineHeight="16px"
              fontWeight="700"
              color={headerColor}
            >
              {termsNegotiated < 10 ? `0${termsNegotiated}` : termsNegotiated}
            </Text>
            <Text fontFamily="Open Sans" color={headerColor}>
              Terms
              <br />
              negotiated
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flex="1"
        flexDirection="column"
        justifyContent="space-between"
        gap="32px"
      >
        <Accordion
          flex="1"
          allowToggle={true}
          variant="panel"
          onChange={onToggleAccordion}
          index={accordionState.index}
        >
          {displaySteps}
        </Accordion>
        <Flex justifyContent="end">
          {isContractSummary ? (
            <Button
              type="button"
              onClick={() => onViewContract()}
              data-heap-id={`${contractTypeName}.view-contract`}
              color={negotiationPanelTheme.buttonTextColor}
              bg={negotiationPanelTheme.buttonColor}
            >
              View Contract
            </Button>
          ) : (
            isUserResponding && (
              <Button
                type="button"
                onClick={() => handleSubmit()}
                disabled={termsRemaining > 0}
                data-heap-id={`${contractTypeName}.submit-negotiation`}
                color={negotiationPanelTheme.buttonTextColor}
                bg={negotiationPanelTheme.buttonColor}
              >
                Submit
              </Button>
            )
          )}
        </Flex>
      </Flex>
      <Drawer
        onClose={onCloseTooltip}
        isOpen={tooltipState.isOpen}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent p="16px" bg="rgba(27, 28, 49, 1)">
          <Heading fontSize="32px">{tooltipState.header}</Heading>
          <ChartaProse mt="16px">{tooltipState.content}</ChartaProse>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
