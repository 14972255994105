import { useEffect, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createContract, getNegotiationById, updateNegotiation } from 'src/api';
import { navbarColorAtom } from 'src/atoms';
import { AlertModal } from 'src/components/core/AlertModal';
import { LoadScreen } from 'src/components/core/LoadScreen';
import { Answer, Negotiation as INegotiation } from 'src/interfaces';
import { NegotiationPanel } from '../components/negotiation/NegotiationPanel';
import { negotiationPanelTheme } from 'src/theme/theme';

export const Negotiation = () => {
  const [negotiation, setNegotiation] = useState<INegotiation | null>();
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenError,
    onClose: onCloseError,
    onOpen: onOpenError
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (location.state?.showSentAlert) {
      onOpen();
    }
  }, [location.state?.showSentAlert, negotiation, onOpen]);

  useEffect(() => {
    const getNegotiation = async () => {
      const { data } = await getNegotiationById(id!);
      setNegotiation(data);
    };

    try {
      setIsLoading(true);
      getNegotiation();
    } catch (error) {
      onOpenError();
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [id, onOpenError, setNegotiation]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAnswers = async (answers: Answer[]) => {
    let cleanAnswers: Answer[] = answers.map(
      ({ stepId, subStepId, questionId, fieldId, value }) => ({
        stepId,
        subStepId,
        questionId,
        fieldId,
        value
      })
    ); //This is because Luke's API doesn't check that form of the answer object - need to fix that

    try {
      setIsLoading(true);
      const { data } = await updateNegotiation(negotiation!._id, {
        answers: cleanAnswers
      });
      setNegotiation(data);
      onOpen();
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewContract = async () => {
    try {
      setIsLoading(true);
      const { data } = await createContract(negotiation!._id);
      setIsLoading(false);
      navigate(`/contract/${data.contract._id}`);
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadScreen label="Generating contract..." />;
  }

  if (!negotiation) {
    return <></>;
  }

  return (
    <Flex
      flexDirection="column"
      h="100vh"
      // bgImage="url(/studio.jpg)"
      // bgPosition="center"
      // bgSize="cover"
      bg={negotiationPanelTheme.background}
      overflowY={{ lg: 'hidden' }}
    >
      <Flex
        h="100%"
        alignItems="center"
        justifyContent="space-between"
        overflowY={{ base: 'auto', lg: 'hidden' }}
      >
        <NegotiationPanel
          negotiation={negotiation}
          onSubmitAnswers={handleSubmitAnswers}
          onViewContract={handleViewContract}
        />
      </Flex>
      <AlertModal
        isOpen={isOpen}
        onAccept={onClose}
        acceptText="Continue"
        title="Your offer has been sent!"
        variant="success"
      />
      <AlertModal
        isOpen={isOpenError}
        onAccept={onCloseError}
        variant="warning"
        title="Looks like something went wrong. The team has been notified and will look into it."
        text="In the case that you are still experiencing the issue, please reach out to support@getcharta.com"
        acceptText="OK"
      />
    </Flex>
  );
};
