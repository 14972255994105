import {
  Heading,
  useColorModeValue,
  FlexProps,
  Flex,
  Text,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";

export interface EnterCodeProps extends FlexProps {
  onCodeEntered: (code: string) => void;
  onCodeRequested: () => void;
}

export const EnterCode: React.FC<EnterCodeProps> = ({
  onCodeEntered,
  onCodeRequested,
  ...props
}) => {
  const color = useColorModeValue("#1B1C31", "brand.secondary1");

  const [code, setCode] = useState<string>("");
  const [resendDisabled, setResendDisabled] = useState<boolean>(false);

  return (
    <Flex p="32px" flexDir="column" alignItems="center" {...props}>
      <Heading fontSize="56px" fontWeight="400" lineHeight="50.4px">
        Reset Password
      </Heading>

      <Text
        align="center"
        mt="24px"
        fontSize="16px"
        fontWeight="400"
        lineHeight="20px"
      >
        Enter the code that was sent to your email.
      </Text>

      <FormControl mt="60px" w={{ base: "100%", lg: "70%" }}>
        <Input
          borderColor={color}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </FormControl>

      <Flex justifyContent="center" width="100%" mt="24px" gap="16px">
        <Button
          variant="outline"
          onClick={() => {
            setResendDisabled(true);
            onCodeRequested();
          }}
        >
          Resend code
        </Button>
        <Button
          w="138px"
          h="40px"
          variant="gradient"
          onClick={() => onCodeEntered(code)}
        >
          Verify
        </Button>
      </Flex>
    </Flex>
  );
};
